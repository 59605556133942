import { Stack } from '@chakra-ui/react';
import SurveyFieldTable from './SurveyFieldTable';

const SurveyBuildTab = ({ survey }) => {
  return (
    <Stack spacing={4}>
      <SurveyFieldTable survey={survey} />
    </Stack>
  );
};

export default SurveyBuildTab;
