import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip as ChakraTooltip,
  useColorMode,
  useDisclosure,
  useTheme,
  useToast,
  Badge,
  HStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsProgress,
  faChartRadar,
  faChartScatter
} from '@fortawesome/pro-regular-svg-icons';
import {
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  RadarChart,
  Radar,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import ChartSkeleton from './ChartSkeleton';
import ButtonAnimator from 'components/ButtonAnimator';
import LoadingWrapper from 'components/LoadingWrapper';
import MembersButton from 'components/MembersButton';
import ModalWrapper from 'components/ModalWrapper';
import RechartsScatterChart from 'components/RechartsScatterChart';
import ScoreTooltip from 'components/tooltips/ScoreTooltip';
import { cardFilterToArray, hasScorePermission } from 'features/card/cardUtils';
import ScoreForm from 'features/card/ScoreForm';
import {
  getCollectingPlural,
  isCollectionManager
} from 'features/collection/collectionUtils';
import ScorersButton from 'features/card/ScorersButton';
import api from 'utils/api';
import AverageScore from './AverageScore';
import MyScore from './MyScore';
import IconTooltip from 'components/tooltips/IconTooltip';
import { useUi } from 'providers/UiProvider';

const calculateAverageValues = (parameters, scores) => {
  const averages = Array(parameters.length).fill(0);
  if (scores && scores.length > 0) {
    scores.forEach(score => {
      score.points.forEach(({ value }, index) => {
        averages[index] += value;
      });
    });
    return averages.map(average => average / scores.length);
  }
  return averages;
};

const calculateTotalValues = (parameters, scores) => {
  const totals = Array(parameters.length).fill(0);
  if (scores && scores.length > 0) {
    scores.forEach(score => {
      score.points.forEach(({ value }, index) => {
        totals[index] += value;
      });
    });
  }
  return totals;
};

const generateChartData = (key, parameters, scores) => {
  const data = [];
  switch (key) {
    case 'SCA':
      scores?.forEach(score => {
        const formattedScore = { id: score.id, comment: score.comment };
        parameters?.forEach((param, index) => {
          formattedScore[param.label] = score.points[index].value;
        });
        if (parameters.length === 2) {
          formattedScore['size'] = 1;
        }
        data.push(formattedScore);
      });
      break;
    case 'RAD':
      const averages = calculateAverageValues(parameters, scores);
      parameters?.forEach((param, index) => {
        data.push({
          label: param.label,
          average: averages[index] / param.max,
          max: param.max,
          fullLabel: param.label
        });
      });
      break;
    default:
      break;
  }
  return data;
};

const Chart = ({ card, chart, field, panel, forReport = false, children }) => {
  const [focusedScore, setFocusedScore] = useState({
    scoreId: null,
    paramId: null
  });

  const [tabIndex, setTabIndex] = useState(0);

  const { colorMode } = useColorMode();

  const {
    isOpen: showScoreModal,
    onClose: closeScoreModal,
    onOpen: openScoreModal
  } = useDisclosure();

  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { t } = useTranslation();
  const { filter } = useUi();

  const queryClient = useQueryClient();

  const theme = useTheme();
  const toast = useToast();

  const {
    status,
    data: scores,
    isSuccess
  } = useQuery(['scores', card.id, chart.id], async () => {
    const { data } = await api.get(`/scores?card=${card.id}&chart=${chart.id}`);
    return data;
  });

  const createScoreMutation = useMutation(score => api.post('/scores', score), {
    onSuccess: ({ data: score }) => {
      closeScoreModal();
      toast({
        title: 'Score was successfully created.',
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
      queryClient.setQueryData(
        ['scores', card.id, chart.id],
        [...scores, score]
      );
      const cards = queryClient.getQueryData([
        'cards',
        { hive: slug },
        ...cardFilterToArray({ ...filter })
      ]);
      if (cards) {
        const pages = cards.pages.map(page => ({
          ...page,
          results: page.results.map(c =>
            c.id === card.id ? { ...c, scored: true } : c
          )
        }));
        queryClient.setQueryData(
          ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
          {
            ...cards,
            pages
          }
        );
      }
    },
    onError: () => {
      toast({
        title: `Could not create score. Please try again later.`,
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    }
  });

  const updateScoreMutation = useMutation(
    ({ scoreId, score }) => api.patch(`/scores/${scoreId}`, score),
    {
      onSuccess: ({ data: score }) => {
        closeScoreModal();
        toast({
          title: 'Score was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['scores', card.id, chart.id],
          scores.map(s => (s.id === score.id ? score : s))
        );
      },
      onError: () => {
        toast({
          title: `Could not update score. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteScoreMutation = useMutation(
    scoreId => api.delete(`/scores/${scoreId}`),
    {
      onSuccess: (_, scoreId) => {
        closeScoreModal();
        toast({
          title: 'Score was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['scores', card.id, chart.id],
          scores.filter(s => s.id !== scoreId)
        );
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => ({
            ...page,
            results: page.results.map(c =>
              c.id === card.id ? { ...c, scored: false } : c
            )
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
      }
    }
  );

  const handleOnCreateScore = async data => {
    const points = [];
    let comment = '';
    for (const [key, value] of Object.entries(data)) {
      if (key === 'comment') {
        comment = value;
      } else {
        points.push({
          parameter: Number.parseInt(key),
          value
        });
      }
    }
    await createScoreMutation.mutateAsync({
      card: card.id,
      chart: chart.id,
      points,
      comment
    });
  };

  const handleOnUpdateScore = async data => {
    const points = [];
    let comment = '';
    for (const [key, value] of Object.entries(data)) {
      if (key === 'comment') {
        comment = value;
      } else {
        points.push({
          parameter: Number.parseInt(key),
          value
        });
      }
    }
    const score = {
      points,
      comment
    };
    const scored = scores?.find(score => score.creator.id === me.id);
    await updateScoreMutation.mutateAsync({
      scoreId: scored.id,
      score
    });
  };

  const handleOnDeleteScore = async scoreId => {
    await deleteScoreMutation.mutateAsync(scoreId);
  };

  const scored = scores?.find(score => score?.creator?.id === me?.id);

  const scoresData = generateChartData(
    chart.parameters.length === 2 || chart.parameters.length === 3
      ? 'SCA'
      : 'RAD',
    chart.parameters,
    scores
  );

  const averages = calculateAverageValues(chart.parameters, scores);
  const totals = calculateTotalValues(chart.parameters, scores);

  const isAdminOrCollectionManager =
    profile?.is_admin || isCollectionManager(card.collection, me);

  const canScore = hasScorePermission(field, card, me, panel);

  return (
    <LoadingWrapper
      indicator={<ChartSkeleton />}
      delay={0}
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.score').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        forReport && scores.length < 1 ? null : (
          <Stack spacing={4} height="full" justifyContent="flex-end">
            <Tabs
              variant="fresh"
              index={tabIndex}
              onChange={index => {
                setTabIndex(index);
              }}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <HStack>
                  <Heading fontSize="md" whiteSpace="wrap">
                    {field.field}
                  </Heading>
                  {field.help_text && (
                    <IconTooltip color="gray" label={field.help_text} />
                  )}
                </HStack>
                <TabList>
                  {isAdminOrCollectionManager && (
                    <>
                      <Tab isDisabled={!field.chart.average}>
                        <FontAwesomeIcon icon={faBarsProgress} />
                      </Tab>
                      <Tab isDisabled={!field.chart.average}>
                        <FontAwesomeIcon
                          icon={
                            chart?.type === 'RAD'
                              ? faChartRadar
                              : faChartScatter
                          }
                        />
                      </Tab>
                    </>
                  )}
                </TabList>
              </Flex>
              <TabPanels>
                <TabPanel py={8}>
                  <SimpleGrid
                    templateColumns={['1fr', null, '2fr 1fr']}
                    spacing={[8, null, 4]}
                  >
                    {isAdminOrCollectionManager ? (
                      <AverageScore
                        chart={chart}
                        averages={averages}
                        totals={totals}
                      />
                    ) : (
                      <MyScore chart={chart} scoring={scored} />
                    )}
                    <Stack
                      justifyContent="space-between"
                      alignItems={['flex-start', null, 'flex-end']}
                      spacing={4}
                      direction={['row', null, 'column']}
                      flexWrap={['wrap', null, 'nowrap']}
                    >
                      {!forReport && (
                        <Stack
                          alignItems={['flex-start', null, 'flex-end']}
                          width={['min-content', null, 'full']}
                        >
                          <Text variant="muted" whiteSpace="break-spaces">
                            {t('card.who_can_score', {
                              cards: getCollectingPlural(card.collection.type)
                            })}
                          </Text>
                          {field.scorer === 'EVE' ? (
                            <Badge>
                              <Text>{t('common.everyone')}</Text>
                            </Badge>
                          ) : field.scorer === 'COL' ? (
                            <Flex justifyContent="flex-end">
                              <MembersButton
                                members={
                                  panel?.members
                                    ? [
                                        ...card.collection.managers,
                                        ...panel.members
                                      ]
                                    : card.collection.managers
                                }
                                max={3}
                              />
                            </Flex>
                          ) : field.scorer === 'CON' ? (
                            <Flex justifyContent="flex-end">
                              <MembersButton
                                members={card.contributors}
                                max={3}
                              />
                            </Flex>
                          ) : null}
                        </Stack>
                      )}
                      <Stack>
                        <Text width="full" variant="muted">
                          {t('card.scored_by')}
                        </Text>
                        <Flex alignSelf="flex-end">
                          <ScorersButton
                            scores={scores}
                            parameters={chart.parameters}
                            isAdminOrCollectionManager={
                              isAdminOrCollectionManager
                            }
                            focusedScore={focusedScore}
                            resetFocusedScore={() =>
                              setFocusedScore({
                                scoreId: null,
                                paramId: null
                              })
                            }
                          />
                        </Flex>
                      </Stack>
                      {!forReport && (
                        <Flex
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          width={['full', 'fit-content', null]}
                        >
                          <ChakraTooltip
                            showTooltip={canScore}
                            step={field.step}
                          >
                            <ButtonAnimator
                              active={
                                canScore &&
                                !card?.collection?.code &&
                                !forReport
                              }
                              colorScheme="blue"
                            >
                              <Button
                                isDisabled={!canScore}
                                colorScheme="blue"
                                onClick={openScoreModal}
                              >
                                {t('common.score_verb')}
                              </Button>
                            </ButtonAnimator>
                          </ChakraTooltip>
                        </Flex>
                      )}
                    </Stack>
                  </SimpleGrid>
                </TabPanel>
                {isAdminOrCollectionManager && (
                  <TabPanel>
                    <Stack>
                      {chart.parameters.length === 2 ||
                      chart.parameters.length === 3 ? (
                        tabIndex === 1 && (
                          <RechartsScatterChart
                            chart={chart}
                            data={scoresData}
                            onClick={scoreId => {
                              setFocusedScore({
                                scoreId: scoreId,
                                paramId: null
                              });
                            }}
                            tooltip={<ScoreTooltip chartType="SCA" />}
                          />
                        )
                      ) : chart.parameters.length !== 3 ? (
                        tabIndex === 1 ? (
                          <ResponsiveContainer
                            minWidth="150px"
                            width="100%"
                            minHeight="300px"
                          >
                            <RadarChart
                              outerRadius={90}
                              width={730}
                              height={250}
                              data={scoresData}
                              stroke={
                                colorMode === 'light'
                                  ? theme.colors.gray['400']
                                  : theme.colors.gray['500']
                              }
                              fill={
                                colorMode === 'light'
                                  ? theme.colors.gray['800']
                                  : theme.colors.whiteAlpha['900']
                              }
                            >
                              <PolarGrid
                                stroke={
                                  colorMode === 'light'
                                    ? theme.colors.gray['400']
                                    : theme.colors.gray['500']
                                }
                                style={{
                                  color:
                                    colorMode === 'light'
                                      ? theme.colors.gray['400']
                                      : theme.colors.gray['500']
                                }}
                              />
                              <PolarAngleAxis
                                dataKey="label"
                                dy={5}
                                height={100}
                                width={100}
                                style={{
                                  color:
                                    colorMode === 'light'
                                      ? theme.colors.gray['400']
                                      : theme.colors.gray['500']
                                }}
                              />
                              <PolarRadiusAxis
                                domain={[0, 1]}
                                tick={false}
                                axisLine={false}
                              />
                              <Tooltip
                                content={
                                  <ScoreTooltip
                                    chartType={
                                      chart.parameters.length === 3
                                        ? 'SCA'
                                        : 'RAD'
                                    }
                                  />
                                }
                                cursor={{
                                  strokeDasharray: '3 3',
                                  stroke:
                                    colorMode === 'light'
                                      ? theme.colors.gray['400']
                                      : theme.colors.gray['500']
                                }}
                              />
                              <Radar
                                dataKey="average"
                                stroke={theme.colors.chart.stroke}
                                fill={theme.colors.chart.fill}
                              />
                            </RadarChart>
                          </ResponsiveContainer>
                        ) : null
                      ) : null}
                    </Stack>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
            {children}
            <ModalWrapper
              title={t('common.score')}
              isOpen={showScoreModal}
              onClose={closeScoreModal}
            >
              <ScoreForm
                defaultValues={scored ? { score: scored } : null}
                chart={chart}
                isOpen={showScoreModal}
                onDelete={handleOnDeleteScore}
                onSubmit={scored ? handleOnUpdateScore : handleOnCreateScore}
              />
            </ModalWrapper>
          </Stack>
        )
      ) : null}
    </LoadingWrapper>
  );
};
export default Chart;
