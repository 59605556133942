import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import IconTooltip from 'components/tooltips/IconTooltip';
import {
  Button,
  Flex,
  FormControl,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Spacer,
  Stack,
  Text,
  Center,
  HStack,
  FormLabel
} from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';

const ScoreForm = ({
  chart,
  defaultValues,
  handleOnFormStateUpdate,
  withoutSubmitButtons = false,
  isOpen,
  onDelete,
  onSubmit
}) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const values = watch();

  useEffect(() => {
    if (handleOnFormStateUpdate) {
      handleOnFormStateUpdate({ isSubmitting, isValid, values });
    }
  }, [handleOnFormStateUpdate, isSubmitting, isValid, values]);

  const scoreCommentMaxLength = 500;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Stack spacing={8}>
          {chart.parameters.map(parameter => (
            <Controller
              key={parameter.id}
              name={parameter.id.toString()}
              control={control}
              defaultValue={() => {
                if (defaultValues?.score) {
                  const point = defaultValues.score.points.find(
                    point => point.parameter === parameter.id
                  );
                  if (point) {
                    return point.value;
                  }
                }
                return parameter.min;
              }}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl id={parameter.id}>
                  <Stack spacing={4}>
                    <HStack>
                      <Text wordBreak="break-word">{parameter.label}</Text>
                      <Center>
                        <IconTooltip label={parameter.description} />
                      </Center>
                      <Spacer />
                      <Text fontWeight="bold">{value}</Text>
                    </HStack>
                    <Stack>
                      <Slider
                        onChange={onChange}
                        defaultValue={value}
                        min={parameter.min}
                        max={parameter.max}
                        step={1}
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                    </Stack>
                  </Stack>
                </FormControl>
              )}
            />
          ))}
        </Stack>
        <FormControl id="comment">
          <FormLabel>{t('common.comment')}</FormLabel>
          <CharacterCounterInput
            isTextArea
            useControllerProps={{
              name: 'comment',
              control: control,
              rules: {
                required: false,
                maxLength: {
                  value: scoreCommentMaxLength,
                  message: t('common.character_limit_error', {
                    limit: scoreCommentMaxLength
                  })
                }
              }
            }}
            defaultValue={defaultValues?.score?.comment}
          />
        </FormControl>
        {!withoutSubmitButtons && (
          <Flex justifyContent="space-between">
            <HStack justifyContent="flex-end">
              {defaultValues?.score && (
                <Button
                  colorScheme="red"
                  onClick={() => onDelete(defaultValues?.score.id)}
                >
                  {t('common.delete')}
                </Button>
              )}
              <Spacer />
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >
                {t('button.save')}
              </Button>
            </HStack>
          </Flex>
        )}
      </Stack>
    </form>
  );
};

export default ScoreForm;
