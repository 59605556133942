import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Center,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import Description from 'components/Description';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import ItemWrapper from 'components/ItemWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const AnswererBlock = ({ answer }) => (
  <HStack alignItems="flex-start">
    <Avatar
      size="sm"
      src={
        answer?.contributors[0]?.avatar
          ? answer.contributors[0]?.avatar
          : answer.contributors[0]?.third_party_avatar
          ? answer.contributors[0]?.third_party_avatar
          : null
      }
      name={answer?.contributors[0]?.full_name}
    />
    <Stack spacing={0} justifyContent="flex-start">
      <Text fontSize="sm" fontWeight="bold">
        {answer?.contributors[0]?.first_name}
      </Text>
      <Text fontSize="xs">
        {format(new Date(answer?.created_at), 'dd MMM yyyy')}
      </Text>
    </Stack>
  </HStack>
);

const AllTextAnswers = ({ survey, code, title }) => {
  const {
    isOpen: showSelectedAnswerModal,
    onOpen: openSelectedAnswerModal,
    onClose: closeSelectedAnswerModal
  } = useDisclosure();

  const [selectedAnswer, setSelectedAnswer] = useState({});

  const { t } = useTranslation();
  const { slug } = useParams();

  const {
    data: answers,
    status,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage
  } = useInfiniteQuery(
    ['answers', survey?.id],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        collection: survey?.id,
        offset: pageParam,
        limit: 20
      });
      params.append('hive', slug);
      const { data } = await api.get(
        code
          ? `/cards?${params.toString()}&code=${code}`
          : `/cards?${params.toString()}`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      },
      enabled: true
    }
  );

  return (
    <>
      <Flex width="full">
        <LoadingWrapper
          statuses={[status]}
          errorMessages={[
            t('common.could_not_fetch_data_please_try_again_later', {
              data: t('common.cards').toLowerCase()
            })
          ]}
        >
          {isSuccess ? (
            answers.pages[0].count > 0 ? (
              <Stack width="full">
                <InfiniteScrollHelper
                  hasMore={!isFetching && hasNextPage}
                  loadMore={fetchNextPage}
                >
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      992: 2,
                      768: 1
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-column"
                  >
                    {answers.pages.map(page =>
                      page.results.map(answer => (
                        <ItemWrapper
                          key={answer.id}
                          onClick={() => {
                            setSelectedAnswer(answer);
                            openSelectedAnswerModal();
                          }}
                        >
                          <Stack spacing={4}>
                            <AnswererBlock answer={answer} />
                            <Description>
                              {answer ? answer.description : ''}
                            </Description>
                          </Stack>
                        </ItemWrapper>
                      ))
                    )}
                  </Masonry>
                </InfiniteScrollHelper>
                {isFetching && (
                  <Center height="30vh" width="full">
                    <LoadingIndicator centered />
                  </Center>
                )}
              </Stack>
            ) : null
          ) : null}
        </LoadingWrapper>
      </Flex>
      <ModalWrapper
        isOpen={showSelectedAnswerModal}
        onClose={closeSelectedAnswerModal}
        title={title}
      >
        <Stack spacing={4} py={4}>
          <AnswererBlock answer={selectedAnswer} />
          <Description>
            {selectedAnswer ? selectedAnswer.description : ''}
          </Description>
        </Stack>
      </ModalWrapper>
    </>
  );
};

export default AllTextAnswers;
