import { isCollectionManager } from 'features/collection/collectionUtils';
import { isCurrentStep } from 'features/workflow/workflowUtils';

export const isProjectOwnerOrMember = (card, me) => {
  if (card && me) {
    if (card.collection.project?.owner.id === me.id) {
      return true;
    } else if (
      card.collection.project?.members.some(member => member.id === me?.id)
    ) {
      return true;
    }
  }
  return false;
};

export const isAssignee = (card, me) => {
  return card?.assignees?.some(member => member.id === me?.id);
};

export const isContributor = (card, me) => {
  return card?.contributors?.some(member => member.id === me?.id);
};

export const hasScorePermission = (field, card, me, panel) => {
  if (panel?.members.some(m => m.id === me?.id)) {
    return true;
  } else if (field.scorer === 'EVE') {
    return true;
  } else if (field.scorer === 'COL') {
    return isCollectionManager(card.collection, me);
  } else if (field.scorer === 'CON') {
    return isContributor(card, me);
  }
  return false;
};

export const checkIfValidUUID = str =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    str
  );

export const findAnswer = (field, answers, isContributor) => {
  if (field.type === 'INPUT') {
    const foundAnswer = answers.find(answer => {
      return answer.field.id === field.id;
    });
    return foundAnswer;
  } else if (field.type === 'MULTI_SELECT') {
    const answer = answers.find(answer => answer.field.id === field.id);
    if (answer) {
      if (isContributor) {
        if (answer.field.options.some(o => o.selected)) {
          return answer.field;
        }
      } else {
        return answer.field;
      }
    }
    return null;
  }
};

export const getStepFields = (card, filter) => {
  if (!card.collection.workflow) return [];

  return card.collection.workflow.fields.filter(field => {
    const fieldStepId = field.step?.id || null;
    const filterStepId = filter?.step || null;

    return isCurrentStep(fieldStepId, filterStepId);
  });
};

export const getContributorFields = (card, me, panel) =>
  card.collection.workflow
    ? card.collection.workflow.fields.filter(
        field =>
          hasScorePermission(field, card, me, panel) &&
          isCurrentStep(
            field.step ? field.step.id : null,
            card.step ? card.step.id : null
          )
      )
    : [];

export const generateCardPayload = (collectionId, card) => {
  const payload = new FormData();
  payload.append('collection', collectionId);
  payload.append('image', card.image ? card.image : '');
  payload.append('unsplash', card.unsplash ? card.unsplash : '');
  payload.append('title', card.title);

  if (card.description) {
    payload.append('description', card.description);
  }

  if (card.fields) {
    const answers = card.fields
      .filter(field => !Array.isArray(field) && !Array.isArray(field.points))
      .map(field => ({
        answer: field.value,
        field: field.id
      }));
    if (answers.length > 0) {
      payload.append('answers', JSON.stringify(answers));
    }

    const scores = card.fields.filter(field => Array.isArray(field.points));

    if (scores.length > 0) {
      payload.append('scores', JSON.stringify(scores));
    }

    let selections = [];
    card.fields
      .filter(field => Array.isArray(field))
      .forEach(field => {
        field.forEach(option => {
          selections.push({ option: option.id });
        });
      });

    if (selections.length > 0) {
      payload.append('selections', JSON.stringify(selections));
    }
  } else {
    payload.append('answers', '');
    payload.append('ratings', '');
    payload.append('selections', '');
  }

  return payload;
};

export const cardFilterToArray = filter => {
  let arr = [];
  if (filter?.collection) {
    arr.push({ collection: filter.collection });
  }
  if (filter?.creator) {
    arr.push({ contributors: filter.creator });
  }
  if (filter?.assignee) {
    arr.push({ assignees: filter.assignee });
  }
  if (filter?.step) {
    if (filter.step === -1) {
      arr.push({ step__isnull: true });
    } else {
      arr.push({ step: filter.step });
    }
  }
  if (filter?.step__isnull) {
    arr.push({ step__isnull: filter.step__isnull });
  }
  if (filter?.tags) {
    filter.tags.forEach(tag => {
      arr.push({ tags: tag });
    });
  }
  if (filter?.workflow) {
    arr.push({ collection__workflow: filter.workflow });
  }
  if (filter?.ordering) {
    arr.push({ ordering: filter.ordering });
  }
  if (filter?.search) {
    arr.push({ search: filter.search });
  }
  if (filter?.type) {
    arr.push({ collection__workflow__type: filter.type });
  }
  if (filter?.options) {
    Object.values(filter?.options)?.forEach(option => {
      arr.push({ selections__option: option });
    });
  }
  return arr;
};

export const appendCardFilterToURLParams = (filter, params) => {
  cardFilterToArray(filter).forEach(entry =>
    params.append(Object.keys(entry)[0], Object.values(entry)[0])
  );
};
