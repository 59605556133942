import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLink, faUser } from '@fortawesome/pro-regular-svg-icons';
import CopyLinkButton from 'components/CopyLinkButton';
import InviteForm from 'components/InviteForm';
import LoadingWrapper from 'components/LoadingWrapper';
import MemberAvatar from 'components/MemberAvatar';
import QrCodeButton from 'components/QrCodeButton';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import api from 'utils/api';
import SpinnerSwitch from 'components/SpinnerSwitch';

let inviteId = null;

const ShareSurvey = ({ surveyId, survey, onInviteSent, onInviteChange }) => {
  const { slug } = useParams();

  const queryClient = useQueryClient();

  const {
    isOpen: showDeleteLinkModal,
    onClose: closeDeleteLinkModal,
    onOpen: openDeleteLinkModal
  } = useDisclosure();

  const {
    status: inviteStatus,
    data: invites,
    isSuccess: inviteIsSuccess,
    refetch
  } = useQuery(['invites', 'survey', surveyId], async () => {
    const { data: invites } = await api.get(
      `/surveys/invites?survey=${surveyId}&hive__slug=${slug}`
    );
    return invites;
  });

  const createInvitesMutation = useMutation(
    async emails =>
      await api.post(`/surveys/${surveyId}/invite?hive__slug=${slug}`, emails),
    {
      onSuccess: () => {
        refetch();
        if (onInviteChange) {
          onInviteChange();
        }
        if (onInviteSent) {
          onInviteSent();
        }
        toast({
          title: 'Invites was successfully sent.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteInviteMutation = useMutation(
    () => api.delete(`/surveys/invites/${inviteId}?hive__slug=${slug}`),
    {
      onSuccess: () => {
        refetch();
        if (onInviteChange) {
          onInviteChange();
        }
      }
    }
  );

  const createLinkMutation = useMutation(
    () => api.post(`/surveys/${survey?.id}/share?hive__slug=${slug}`),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', surveyId], data);
      }
    }
  );

  const deleteLinkMutation = useMutation(
    () => api.delete(`/surveys/${survey?.id}/share?hive__slug=${slug}`),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', surveyId], data);
        closeDeleteLinkModal();
      }
    }
  );

  const updateSurveyFieldMutation = useMutation(
    payload => api.patch(`/surveys/${survey?.id}?hive__slug=${slug}`, payload),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(['survey', surveyId], data);
      }
    }
  );

  const { t } = useTranslation();
  const toast = useToast();

  const handleOnCreateInvites = async values => {
    const emails = values.emails
      .map(email => email.value)
      .filter(e => /\S+@\S+\.\S+/.test(e));
    if (emails.length > 0) {
      await createInvitesMutation.mutateAsync({
        emails,
        subject: values.subject,
        message: values.message
      });
    }
  };

  const handleOnClickDeleteInvite = async () => {
    await deleteInviteMutation.mutateAsync();
  };

  const handleOnCreateLink = async () => {
    await createLinkMutation.mutateAsync();
  };

  const handleOnDeleteLink = async () => {
    await deleteLinkMutation.mutateAsync();
  };

  const handleOnChangeShareSocialMedia = async () => {
    await updateSurveyFieldMutation.mutateAsync({
      share_social_media: !survey.share_social_media
    });
  };

  const handleOnChangeAcceptAnonymousContributions = async () => {
    await updateSurveyFieldMutation.mutateAsync({
      accept_anonymous_contributions: !survey.accept_anonymous_contributions
    });
  };

  const url = `${process.env.REACT_APP_URL}/${slug}/shared/survey/${survey.code}`;

  return (
    <Stack spacing={4}>
      <Text variant="muted">{t('common.share_via')}</Text>
      <Tabs size="sm" variant="fresh" isLazy>
        <TabList>
          <Tab>
            <HStack>
              <FontAwesomeIcon icon={faLink} />
              <Text>{t('common.link_and_qr_code')}</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack>
              <FontAwesomeIcon icon={faUser} />
              <Text>{t('common.email_invite')}</Text>
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Stack spacing={8}>
              <>
                <Stack spacing={4}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="muted">{t('common.activate_link')}</Text>
                    <SpinnerSwitch
                      isChecked={survey.code ? true : false}
                      optionKey="code"
                      onChange={() => {
                        if (survey.code) {
                          openDeleteLinkModal();
                        } else {
                          handleOnCreateLink();
                        }
                      }}
                    />
                  </Flex>
                  <FormControl>
                    {survey.code && (
                      <HStack>
                        <Input value={url} readOnly />{' '}
                        <CopyLinkButton
                          variant="solid"
                          colorScheme="blue"
                          icon={faCopy}
                          label={t('button.copy')}
                          url={url}
                        />
                      </HStack>
                    )}
                    <FormHelperText whiteSpace="pre-line">
                      {survey.code
                        ? t('survey.activate_link_on_explainer')
                        : t('survey.activate_link_off_explainer')}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {survey.code && (
                  <QrCodeButton
                    value={url}
                    filename="link.svg"
                    explainer={t('common.share_link_on_qr_code_explainer')}
                  />
                )}
                {survey.code && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="muted">
                      {t('common.share_social_media')}
                    </Text>
                    <SpinnerSwitch
                      isChecked={survey.share_social_media}
                      optionKey="share_social_media"
                      onChange={handleOnChangeShareSocialMedia}
                    />
                  </Flex>
                )}
                {survey.code && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="muted">
                      {t('common.accept_anonymous_contributions')}
                    </Text>
                    <SpinnerSwitch
                      isChecked={survey.accept_anonymous_contributions}
                      optionKey="accept_anonymous_contributions"
                      onChange={handleOnChangeAcceptAnonymousContributions}
                    />
                  </Flex>
                )}
              </>
            </Stack>
          </TabPanel>
          <TabPanel>
            <Stack spacing={8}>
              <FormControl>
                <FormHelperText whiteSpace="pre-line">
                  {t('survey.email_invite_explainer')}
                </FormHelperText>
              </FormControl>
              <InviteForm
                defaultValues={{
                  subject: 'Your feedback is wanted!',
                  message:
                    'I have a collection that I would appreciate your input on. Your thoughts would be valuable.'
                }}
                onSubmit={handleOnCreateInvites}
              />
              <LoadingWrapper
                statuses={[inviteStatus]}
                errorMessages={[
                  t('common.could_not_fetch_data_please_try_again_later', {
                    data: t('common.card').toLowerCase()
                  })
                ]}
              >
                {inviteIsSuccess && invites.length > 0 && <Divider />}
                {inviteIsSuccess && invites.length > 0 && (
                  <Stack spacing={4}>
                    <Text>{t('common.invited_people')}</Text>
                    {invites.map(invite => (
                      <Flex
                        key={invite.id}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <HStack>
                          {invite.user ? (
                            <MemberAvatar member={invite.user} size="sm" />
                          ) : (
                            <Avatar size="sm" />
                          )}
                          <Text>
                            {invite.user ? invite.user.full_name : invite.email}
                          </Text>
                        </HStack>
                        <Button
                          variant="link"
                          onClick={() => {
                            inviteId = invite.id;
                            handleOnClickDeleteInvite();
                          }}
                        >
                          {t('button.remove')}
                        </Button>
                      </Flex>
                    ))}
                  </Stack>
                )}
              </LoadingWrapper>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ConfirmationModal
        deleteHeader={t('common.deactivate_link')}
        deleteText={t('common.deactivate_link_warning')}
        buttonText={t('common.deactivate')}
        isOpen={showDeleteLinkModal}
        onClose={closeDeleteLinkModal}
        onDelete={() => {
          handleOnDeleteLink();
        }}
      />
    </Stack>
  );
};

export default ShareSurvey;
