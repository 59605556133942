import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const ChangeFolderModal = ({
  folder,
  isOpen,
  onClose,
  onChange,
  isClearable = true,
  isRequired = false
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status,
    data: folders,
    isSuccess,
    refetch
  } = useQuery(
    ['folders'],
    async () => {
      const { data } = await api.get(`/folders?hive__slug=${slug}`, {
        timeout: 30000
      });
      return data;
    },
    {
      enabled: false
    }
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    folder
  });

  useEffect(() => {
    if (!isOpen) {
      reset(folder);
    }
  }, [isOpen, folder, reset]);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <ModalWrapper
      title={t('common.folder')}
      isOpen={isOpen}
      onClose={onClose}
      pb={8}
    >
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.folders').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <form onSubmit={handleSubmit(onChange)}>
            <Stack spacing={4}>
              <FormControl id="name">
                <FormLabel>{t('common.name')}</FormLabel>
                <Controller
                  name="folder"
                  control={control}
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={folder}
                      options={folders}
                      placeholder={t('placeholder.search')}
                      getOptionLabel={folder => folder.name}
                      getOptionValue={folder => folder.id}
                      isClearable={isClearable}
                      useBasicStyles
                    />
                  )}
                />
              </FormControl>
              <Flex justifyContent="flex-end">
                <Button
                  type="submit"
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                >
                  {t('button.save')}
                </Button>
              </Flex>
            </Stack>
          </form>
        )}
      </LoadingWrapper>
    </ModalWrapper>
  );
};

export default ChangeFolderModal;
