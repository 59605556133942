import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Box,
  Button,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from '@chakra-ui/react';
import {
  faChartScatter,
  faExpand,
  faTable
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingWrapper from 'components/LoadingWrapper';
import RechartsScatterChart from 'components/RechartsScatterChart';
import ScoreTooltip from 'components/tooltips/ScoreTooltip';
import ScoreTable from 'features/collection/ScoreTable';
import api from 'utils/api';
import { useState } from 'react';
import ModalWrapper from 'components/ModalWrapper';

const ChartResult = ({ chart, collectionId, stepId }) => {
  const { t } = useTranslation();

  const {
    isOpen: showFullscreenModal,
    onOpen: openFullscreenModal,
    onClose: closeFullscreenModal
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const {
    data: results,
    status: resultsStatus,
    isSuccess: resultsIsSuccess
  } = useQuery(['results', chart.id, collectionId, stepId], async () => {
    const params = new URLSearchParams({
      collection_id: collectionId
    });
    if (stepId) {
      params.append('step_id', stepId);
    }
    const { data } = await api.get(
      `/charts/${chart.id}/results?${params.toString()}`
    );
    return data;
  });

  const chartParameters = chart?.parameters;
  const chartData = results?.map(entry => ({
    title: entry.title,
    id: entry.id
  }));
  chartData?.forEach((entry, index) => {
    chartParameters?.forEach(param => {
      entry[param.label] = results[index]?.average_score[param.position - 1];
    });
  });

  const {
    status: scoresStatus,
    data: scores,
    isSuccess: scoresIsSuccess
  } = useQuery(['scores', chart.id], async () => {
    const { data } = await api.get(`/scores?chart=${chart.id}`);
    return data;
  });

  return (
    <>
      <LoadingWrapper
        statuses={[resultsStatus, scoresStatus]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.chart').toLowerCase()
          })
        ]}
      >
        {resultsIsSuccess && scoresIsSuccess && (
          <Box width="full" height="full">
            <Tabs
              size="sm"
              variant="fresh"
              index={tabIndex}
              onChange={tabIndex => {
                setTabIndex(tabIndex);
              }}
            >
              <HStack justifyContent="space-between">
                <TabList>
                  <Tab>
                    <FontAwesomeIcon icon={faTable} />
                  </Tab>
                  {chart.parameters.length === 3 && (
                    <Tab>
                      <FontAwesomeIcon icon={faChartScatter} />
                    </Tab>
                  )}
                </TabList>
                <Button variant="outline" onClick={openFullscreenModal}>
                  <FontAwesomeIcon icon={faExpand} />
                </Button>
              </HStack>
              <TabPanels>
                <TabPanel>
                  <ScoreTable
                    chart={chart}
                    scores={scores}
                    results={results}
                    maxHeight="45%"
                  />
                </TabPanel>
                {chart.parameters.length === 3 && tabIndex === 1 && (
                  <TabPanel>
                    <RechartsScatterChart
                      chart={chart}
                      data={chartData}
                      tooltip={<ScoreTooltip chartType="SCA" isCardAverage />}
                      height="65vh"
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        )}
        <ModalWrapper
          isOpen={showFullscreenModal}
          onClose={closeFullscreenModal}
          size="full"
          maxWidth="full"
        >
          {tabIndex === 0 ? (
            <Flex height="full">
              <ScoreTable
                chart={chart}
                scores={scores}
                results={results}
                maxHeight="70%"
                wideHeaders
              />
            </Flex>
          ) : chart.parameters.length === 3 && showFullscreenModal ? (
            <RechartsScatterChart
              chart={chart}
              data={chartData}
              tooltip={<ScoreTooltip chartType="SCA" isCardAverage />}
              height="85vh"
            />
          ) : null}
        </ModalWrapper>
      </LoadingWrapper>
    </>
  );
};

export default ChartResult;
