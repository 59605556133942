import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';

const SurveyFieldForm = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,

    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <FormControl id="field">
          <FormLabel>{t('common.title')}</FormLabel>
          <Input
            {...register('field', { required: true })}
            placeholder={t('placeholder.enter_field')}
            defaultValue={defaultValues?.field}
            autoFocus
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default SurveyFieldForm;
