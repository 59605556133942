import { useTranslation } from 'react-i18next';
import { Container, Heading, Stack } from '@chakra-ui/react';
import FolderUncategorizedCollections from './FolderUncategorizedCollections';

const FolderUncategorizedPage = () => {
  const { t } = useTranslation();
  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Heading fontSize="3xl">{t('common.uncategorized')}</Heading>
        <FolderUncategorizedCollections />
      </Stack>
    </Container>
  );
};

export default FolderUncategorizedPage;
