import { Reorder } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Tr,
  useToast,
  useDisclosure,
  Thead,
  Th,
  HStack,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ActionMenu, {
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import { getFieldTypeLabel } from 'features/workflow/workflowUtils';
import api from 'utils/api';
import SurveyFieldForm from './SurveyFieldForm';

let timer = null;

const SurveyFieldTable = ({ survey }) => {
  const [selectedField, setSelectedField] = useState();

  const {
    isOpen: showCreateFieldModal,
    onOpen: openCreateFieldModal,
    onClose: closeCreateFieldModal
  } = useDisclosure();

  const {
    isOpen: showDeleteFieldModal,
    onOpen: openDeleteFieldModal,
    onClose: closeDeleteFieldModal
  } = useDisclosure();

  const {
    isOpen: showUpdateFieldModal,
    onOpen: openUpdateFieldModal,
    onClose: closeUpdateFieldModal
  } = useDisclosure();

  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: fields,
    refetch,
    isSuccess
  } = useQuery(['survey', survey.id, 'fields'], async () => {
    const params = new URLSearchParams({
      survey: survey.id
    });
    const { data } = await api.get(`/surveys/fields?${params.toString()}`);
    return data;
  });

  const createFieldMutation = useMutation(
    field => api.post('/surveys/fields', field),
    {
      onSuccess: ({ data: field }) => {
        closeCreateFieldModal();
        toast({
          title: 'Field was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['survey', survey.id, 'fields'],
          [...fields, field]
        );
      },
      onError: () => {
        toast({
          title: `Could not create field. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteFieldMutation = useMutation(
    fieldId => api.delete(`/surveys/fields/${fieldId}`),
    {
      onSuccess: () => {
        closeDeleteFieldModal();
        queryClient.setQueryData(
          ['survey', survey.id, 'fields'],
          fields.filter(s => s.id !== selectedField.id)
        );
        toast({
          title: 'Field was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        refetch();
      }
    }
  );

  const updateFieldMutation = useMutation(
    field => api.patch(`/surveys/fields/${selectedField.id}`, field),
    {
      onSuccess: ({ data: field }) => {
        closeUpdateFieldModal();
        toast({
          title: 'Field was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['surveys', survey.id, 'fields'],
          fields.map(f => (f.id === field.id ? field : f))
        );
        refetch();
      },
      onError: () => {
        toast({
          title: `Could not update field. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnClickDelete = async () => {
    await deleteFieldMutation.mutateAsync(selectedField.id);
  };

  const handleOnCreateField = async (field, type = 'INPUT') => {
    console.log({ ...field, type });

    await createFieldMutation.mutateAsync({
      ...field,
      survey: survey.id,
      type
    });
  };

  const handleOnUpdateField = async field => {
    await updateFieldMutation.mutateAsync({
      ...field
    });
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.fields').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Stack spacing={4}>
          <Reorder.Group
            axis="y"
            values={fields}
            onReorder={fields => {
              if (timer != null) {
                clearTimeout(timer);
              }
              timer = setTimeout(() => {
                try {
                  const fields = queryClient.getQueryData([
                    'survey',
                    survey.id,
                    'fields'
                  ]);
                  api.patch(`/surveys/${survey.id}/reorder-fields`, {
                    fields: fields.map(field => field.id)
                  });
                } catch (e) {
                  console.log(e);
                }
              }, 1000);
              queryClient.setQueryData(['survey', survey.id, 'fields'], fields);
            }}
          >
            <Table variant="unstyled">
              <Thead>
                <Tr>
                  <Th pl={0}>{t('common.field')}</Th>
                  <Th textAlign="center">{t('common.type')}</Th>
                  <Th pr={0} textAlign="right">
                    {t('common.edit')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {fields.map(field => (
                  <Reorder.Item as={Tr} key={field.id} value={field}>
                    <Td pl={0}>
                      <HStack>
                        <Text cursor="grab" variant="light">
                          <FontAwesomeIcon icon={faGripDots} />
                        </Text>
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedField(field);
                            openUpdateFieldModal();
                          }}
                        >
                          {field.field}
                        </Button>
                      </HStack>
                    </Td>
                    <Td textAlign="center">{getFieldTypeLabel(field)}</Td>
                    <Td pr={0} textAlign="right">
                      <ActionMenu>
                        <EditMenuItem
                          onClick={() => {
                            setSelectedField(field);
                            openUpdateFieldModal();
                          }}
                        />
                        <DeleteMenuItem
                          onClick={() => {
                            setSelectedField(field);
                            openDeleteFieldModal();
                          }}
                        />
                      </ActionMenu>
                    </Td>
                  </Reorder.Item>
                ))}
              </Tbody>
            </Table>
          </Reorder.Group>
          <Button
            variant="link"
            alignSelf="flex-start"
            onClick={openCreateFieldModal}
          >
            {t('button.add_field')}
          </Button>
          <ModalWrapper
            title={t('template.create_field')}
            size="full"
            isOpen={showCreateFieldModal}
            onClose={closeCreateFieldModal}
          >
            <SurveyFieldForm
              onSubmit={async values =>
                await handleOnCreateField(values, 'INPUT')
              }
            />
          </ModalWrapper>
          <ModalWrapper
            title={getFieldTypeLabel(selectedField)}
            isOpen={showUpdateFieldModal}
            onClose={closeUpdateFieldModal}
            size="2xl"
          >
            <Stack>
              <SurveyFieldForm
                defaultValues={{ field: selectedField?.field }}
                onSubmit={handleOnUpdateField}
              />
            </Stack>
          </ModalWrapper>
          <ConfirmationModal
            deleteText={t(
              'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
              { data: t('common.field').toLowerCase() }
            )}
            isOpen={showDeleteFieldModal}
            onClose={closeDeleteFieldModal}
            onDelete={handleOnClickDelete}
          />
        </Stack>
      ) : null}
    </LoadingWrapper>
  );
};

export default SurveyFieldTable;
