import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  Textarea
} from '@chakra-ui/react';

const InputForm = ({
  defaultValue,
  withoutSubmitButtons = false,
  handleOnFormStateUpdate,
  helpText,
  isOpen,
  onSubmit,
  ...rest
}) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const values = watch();

  useEffect(() => {
    if (handleOnFormStateUpdate) {
      handleOnFormStateUpdate({ isSubmitting, isValid: true, values });
    }
  }, [handleOnFormStateUpdate, isSubmitting, values]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <Stack spacing={4}>
        <FormControl id="value">
          <Textarea
            rows={5}
            {...register('value')}
            defaultValue={defaultValue}
            placeholder={t('placeholder.your_response')}
          />
          {helpText && <FormHelperText>{helpText}</FormHelperText>}
        </FormControl>
        {!withoutSubmitButtons && (
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default InputForm;
